@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-modal {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        overflow-y: auto;

        &--is-show {
            display: block;
        }
        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            background: #e5e5e5;
        }
        &__wrapper {
            position: absolute;
            width: 978px;
            height: auto;
            min-height: 335px;
            left: 50%;
            top: 98px;
            transform: translateX(-50%);
            background: cssvar(grayscale-0-color);
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06),
                0px 3px 18px rgba(0, 0, 0, 0.06),
                0px 3px 6px rgba(0, 0, 0, 0.18);
            border-radius: 12px;
            overflow: hidden;
            z-index: 2;
        }
        &__progress-bar {
            height: 7px;
            background: cssvar(button-secondary-color);
        }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: cssvar(spacing-3) cssvar(spacing-3);
        }
        &__inner {
            padding: cssvar(spacing-1) cssvar(spacing-6) cssvar(spacing-5);
            max-height: calc(
                100vh - (98px * 2)
            ); // viewport height subtracts top and bottom space
        }
    }
}
