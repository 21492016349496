@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

$prefix: '.rbk';

body,
html {
    margin: 0;
    padding: 0;
}
#app {
    --rv-danger-color: #{cssvar(danger-color)};
}
#{$prefix} {
    &-icon-alignment {
        display: inline-block;
        vertical-align: middle;
    }
    &-text--warning {
        color: cssvar(warning-dark-color);
        svg {
            fill: cssvar(warning-dark-color);
        }
    }
    &-zebra-bg--warning {
        background-image: repeating-linear-gradient(
            45deg,
            cssvar(grayscale-10-color),
            cssvar(grayscale-10-color) 23px,
            cssvar(bg-primary-color) 23px,
            cssvar(bg-primary-color) 46px
        );
    }
    &-text-align {
        &--right {
            text-align: right;
        }
        &--center {
            text-align: center;
        }
    }
    &-fine-tag {
        font-size: calc(cssvar(font-size-small) - 2px);
        &:not(:last-of-type) {
            margin-right: cssvar(spacing-1);
        }
    }
    &-fixed-bottom-box {
        position: fixed;
        right: cssvar(spacing-5);
        bottom: cssvar(spacing-3);
        width: 100%;
    }
}

a#{$prefix}-reset-underline {
    &,
    &:hover {
        text-decoration: none;
    }
}

// global styling elements
.ribbon {
    position: absolute;
    top: 50%;
    left: 21px;
    max-width: 100px; // to not go ove the suggestion box
    transform: rotate(90deg) translateX(-50%);
    padding: 0 8px;
    z-index: 100;
    transform-origin: 0 0;
    border-radius: 5px 5px 0 0;
    background-color: cssvar(grayscale-30-color);

    &--right {
        transform: rotate(-90deg) translateX(-50%);
        left: auto;
        right: -35px;
    }

    span {
        display: block;
        width: 100%;
        height: 20px;
        transform: rotate(180deg);
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 2px;
    }
}
