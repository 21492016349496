@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-active-title {
        display: flex;
        align-items: center;
        margin-bottom: cssvar(spacing-2);
        &__action {
            width: 25%;
        }
        &__text {
            width: 50%;
            text-align: center;
        }
    }
    &-order-search__button {
        margin-top: 27px;
    }
}
