@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-status-table {
        &--update {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 3px;
                background-color: cssvar(success-color);
                animation: strobe 1.5s ease-in-out infinite alternate;
            }
        }
    }
    &-status-inline {
        white-space: nowrap;
        &__item {
            display: inline-block;
            margin-right: cssvar(spacing-4);
        }
    }
    &-status {
        &__link {
            text-decoration: none;
        }
    }
}

@keyframes strobe {
    0% {
        right: 100%;
    }
    50% {
        right: 0;
    }
    100% {
        left: 100%;
    }
}
