@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-sub-page {
        width: 860px;
        max-width: 100%;
        margin: 0 auto;

        &--wide {
            // space according to the design updates
            width: 1110px;

            .rbk-sub-page__header {
                // space according to the design updates
                padding-left: 127px;
                padding-right: 127px;
            }
            .rbk-sub-page__inner {
                // space according to the design updates
                padding-left: 167px;
                padding-right: 167px;
            }
        }

        &__wrapper {
            width: 100%;
            min-height: 335px;
            background: cssvar(grayscale-0-color);
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06),
                0px 3px 18px rgba(0, 0, 0, 0.06),
                0px 3px 6px rgba(0, 0, 0, 0.18);
            border-radius: 12px;
            overflow: hidden;
        }
        &__progress-bar {
            height: 7px;
            background: cssvar(button-secondary-color);
        }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: cssvar(spacing-3) cssvar(spacing-3);
        }
        &__inner {
            padding: cssvar(spacing-1) cssvar(spacing-4) cssvar(spacing-4);
        }
        &__footer {
            margin-top: cssvar(spacing-6);
            &--left {
                display: flex;
                justify-content: flex-start;
            }
            &--right {
                display: flex;
                justify-content: flex-end;
            }
            &--center {
                display: flex;
                justify-content: center;
            }
        }
    }
}
