@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk-ic-ride-info-tooltip {
    display: block;
}

.rbk-order-box-ic {
    $border: 2px solid cssvar(warning-color);
    $borderRadius: 5px;

    &--selected {
        position: relative;
        display: inline-block;
        padding: 0 0 3px 0;
        margin-bottom: calc(cssvar(spacing-1) / 2);

        &:before {
            content: '';
            display: block;
            position: absolute;
            border-top: $border;
            border-bottom: $border;
            top: 0;
            bottom: 0;
            left: -1px;
            right: -1px;
        }
        &:first-of-type {
            padding-left: 4px;
            &:before {
                border-left: $border;
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;
                left: 0;
            }
        }
        &:last-of-type {
            &:before {
                border-right: $border;
                //border-radius: 0 5px 5px 0;
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
                right: -4px;
            }
        }
    }
}
