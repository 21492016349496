@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.order-info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 cssvar(spacing-2) cssvar(spacing-2) cssvar(spacing-2);

    &--origin {
        background-color: cssvar(grayscale-10-color);
    }
    &--fade {
        color: cssvar(grayscale-50-color);
    }
    &--summary {
        margin: 0 0 6px 0;
        border-radius: 6px;
        padding-bottom: 0;
        border: 1px solid cssvar(grayscale-100-color);

        .order-info__tags {
            position: absolute;
            right: cssvar(spacing-1);
            bottom: cssvar(spacing-2);
        }
    }

    &__uid {
        font-weight: bold;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 cssvar(spacing-4) 0 cssvar(spacing-3);

        .hci-icon {
            min-width: 36px;
            min-height: 36px;

            svg {
                width: 36px;
                height: 36px;
                fill: cssvar(primary-icon-color);
            }
        }
    }

    &__addition-info {
        margin-top: cssvar(spacing-2);
        display: flex;
        justify-content: space-between;
    }

    &__tags {
        & > div {
            margin-right: cssvar(spacing-2);

            svg {
                fill: cssvar(primary-icon-color);
                max-height: 15px;
                margin-top: 1px;
            }

            .hcr-tag__text {
                font-size: 14px;
            }
        }
    }
    &__wrapper {
        margin-top: cssvar(spacing-2);
        margin-bottom: cssvar(spacing-2);
    }

    .rbk-icon--green {
        svg {
            fill: cssvar(success-color);
        }
    }
}
