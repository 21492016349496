@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.alternatives-scroll-wrapper {
    min-height: 200px;
    height: auto;
    max-height: calc(
        100vh - 610px
    ); // viewport height subtracts modal header, inner top, station drop-down and bottom space
    overflow: auto;
    &--full {
        max-height: 600px;
    }
}
