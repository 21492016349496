@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-ic-panel {
        &__transfer {
            &-inner {
                background-color: cssvar(grayscale-10-color);
                font-size: 14px;
                width: calc(100% + 50px);
            }
            div {
                &:before {
                    display: none;
                }
            }
        }
        &__stop {
            &--greyout {
                color: cssvar(grayscale-50-color);
            }
        }
    }
}
