@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-layout-section {
        width: 1280px;
        max-width: 100%;
        margin: 0 auto cssvar(spacing-3);
    }
    &-info-strip {
        width: 100%;
        padding: cssvar(spacing-2) 0;
        margin-bottom: cssvar(spacing-5);
        background: rgba(166, 236, 85, 0.2);
        border: 1px solid cssvar(primary-brand-color);
        &--plain {
            margin-bottom: cssvar(spacing-2);
            background-color: cssvar(bg-primary-color);
            border-color: cssvar(line-primary-color);
        }
        .rbk-layout-section {
            margin-bottom: 0;
        }
    }
    &-illustration {
        width: 100%;
        height: 25.63vw;
        margin-bottom: -11vw;
        margin-top: calc(-1 * #{cssvar(spacing-4)});
        background-image: url('./SSO-Illustration_v2_04.png');
        background-size: cover;
    }
    &-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: cssvar(spacing-6) 0;
        gap: cssvar(spacing-2);
    }
}
