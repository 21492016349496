@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk-capacity {
    &-icon {
        display: inline-block;
        margin-right: cssvar(spacing-2);
        &:last-of-type {
            margin-right: 0;
        }
        &--danger {
            svg {
                fill: cssvar(danger-color);
            }
        }
    }
}
