@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rs-combo-picker {
    position: relative;
    display: flex;
    margin-bottom: cssvar(spacing-4);
    align-items: flex-end;

    &__box {
        position: relative;
        flex-grow: 1;
    }

    &__actions {
        flex-grow: 0;
    }

    &__left,
    &__right {
        display: inline-block;
    }
    &__left {
        width: 51%;

        [class*='input__container'] {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 24px;

            &:focus {
                border-right: none;
            }
        }
    }
    &__right {
        width: 49%;

        [class*='input__container'] {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
                border-left: none;
            }
        }
    }
    &__switch {
        position: absolute;
        top: 50%;
        left: 49%;
        height: 32px;
        width: 32px;
        margin-top: -4px;
        text-align: center;
        line-height: 30px;
        border: 1px solid cssvar(line-primary-color);
        border-radius: 100%;
        background-color: cssvar(bg-primary-color);
        transform: translate(-50%) rotate(0deg);
        cursor: pointer;
        transition: transform 0.4s;
        appearance: none;

        &--rotate {
            transform: translate(-50%) rotate(180deg);
        }
    }
}
