@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.date-picker-row {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 1px;
    margin-bottom: cssvar(spacing-4);
    &__button,
    &__option {
        appearance: none;
        font-weight: cssvar(font-weight-semibold);
        min-width: cssvar(input-height-desktop);
        height: cssvar(input-height-desktop);
        line-height: cssvar(input-height-desktop);
        margin-top: -1px;
        margin-left: -1px;
        border: 1px solid cssvar(input-border-color);
        text-align: center;
        background: cssvar(primary-bg-color);
        cursor: pointer;
    }
    &__option {
        flex-grow: 2;
        &:hover {
            background: cssvar(hover-layer-color);
        }
        &--active {
            box-shadow: 0 -3px 0 0 cssvar(ui-primary-color) inset;
        }
    }
    &__button {
        width: 49px;
        line-height: cssvar(line-height-small);
        &:hover {
            background: cssvar(hover-layer-color);
        }
        &:first-of-type {
            border-radius: cssvar(primary-border-radius) 0 0
                cssvar(primary-border-radius);
        }
        &:last-of-type {
            border-radius: 0 cssvar(primary-border-radius)
                cssvar(primary-border-radius) 0;
        }
    }
}
