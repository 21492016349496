@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.orders-group {
    &__heading {
        position: relative;
        padding: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        padding: cssvar(spacing-2) 0 cssvar(spacing-1);
        margin: 0 -9px; // to better match the container
        list-style: none;

        li {
            margin: 0 cssvar(spacing-3) cssvar(spacing-1) cssvar(spacing-2); // to better match the container
            display: flex;
            align-items: center;
            justify-content: flex-start;

            // adjust icon position a bit
            a {
                margin-left: 4px;
                margin-top: -2px;
                color: inherit;
            }
        }

        &-tooltip {
            margin-left: 4px;
            margin-bottom: 2px;
            display: inline-block;
        }
    }
    &__arrow {
        position: absolute;
        top: -2px;
        right: -6px;
        cursor: pointer;
    }
    &__expand-collapse {
        max-height: 0;
        overflow: hidden;
        transition: max-height 200ms;

        &--expanded {
            max-height: 200px;
        }
    }
    &-options--title {
        text-align: center;
    }
}

.align-center {
    display: flex;
    align-items: center;
}
