@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rb {
    &-container {
        min-height: calc(100vh - 108px);
        background: cssvar(secondary-bg-color);
        padding: cssvar(spacing-4) 0;

        @media (min-width: 1200px) {
            min-height: calc(100vh - 60px);
        }
        @media (max-width: 600px) {
            min-height: calc(100vh - 60px);
        }
    }
}

.modal-is-show {
    overflow: hidden;
}
