@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.earlier-later-tags {
    &__tag {
        margin: 3px cssvar(spacing-1) 0 0;

        &--diff {
            color: cssvar(grayscale-70-color);
            border-color: cssvar(grayscale-70-color);
            background: none;
        }
    }
}
