.language-switcher {
    font-size: 16px;
    font-weight: 300;
    padding: 0 24px;
    margin: 0;
    color: #444;
    list-style: none;
    display: inline-block;
}

@media (min-width: 600px) {
    .language-switcher {
        font-size: 14px;
        padding: 0;
        margin: 0;
    }
}

@media (min-width: 600px) {
    .language-switcher__container {
        position: relative;
    }
}

@media (min-width: 600px) {
    .language-switcher__container:hover .language-switcher__list {
        display: flex;
        left: auto;
        width: auto;
        flex-wrap: wrap;
        flex-direction: column;
        overflow: visible;
    }
}

.language-switcher__active-lng {
    position: relative;
    display: inline-block;
    height: 60px;
    width: 100%;
    line-height: 60px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
}

@media (min-width: 600px) {
    .language-switcher__active-lng {
        border: none;
    }
}

.language-switcher__list {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 0;
    list-style: none;
    background-color: #fff;
    overflow: auto;
    cursor: pointer;
    transition: left 0.25s ease;
}

@media (min-width: 600px) {
    .language-switcher__list {
        display: none;
        right: 0;
        left: auto;
        top: 100%;
        min-width: 200px;
        width: auto;
        height: auto;
        margin-top: -10px;
        padding: 12px;
        color: #444;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
        z-index: 1000;
    }

    .language-switcher__list:before {
        content: '';
        position: absolute;
        top: -5px;
        right: 24px;
        width: 0;
        height: 0;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
}

@media (min-width: 0) and (max-width: 599px) {
    .language-switcher__list--active {
        display: inline;
        display: initial;
        left: 0;
    }
}

.language-switcher__item {
    position: relative;
    padding: 0 24px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    border-bottom: 0;
    -ms-flex-preferred-size: 100px;
}

@media (min-width: 600px) {
    .language-switcher__item {
        padding: 0 12px;
        margin-bottom: 12px;
    }
}

.language-switcher__item:hover:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 4px solid #ffad00;
}

.language-switcher__img {
    display: inline-block;
    width: 21px;
    height: 21px;
    vertical-align: middle;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: 0 0 1px 1px #a1a1a1;
    background-color: #fff;
}

.language-switcher__close-list {
    padding: 0 24px;
}

@media (min-width: 600px) {
    .language-switcher__close-list {
        display: none;
    }
}

.language-switcher__lng-text {
    display: inline-block;
    height: 60px;
    width: 100%;
    line-height: 60px;
}

@media (min-width: 600px) {
    .language-switcher__lng-text {
        height: auto;
        line-height: normal;
        border-bottom: none;
    }
}
