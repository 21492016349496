@import '@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.rbk {
    &-order-box {
        &__heading {
            span {
                font-weight: 400; // missing css var for font weight
            }
        }
        &__tooltip {
            margin-left: 5px;
            margin-bottom: 3px;
        }
        &-ic {
            &__tag {
                font-size: calc(cssvar(font-size-small) - 2px);
            }
        }
    }
    &-options-slot {
        margin-top: cssvar(spacing-2);
        &__item {
            display: flex;
            justify-content: center;
            min-height: 120px;
        }
    }
    &-justify {
        &--right {
            display: flex;
            justify-content: flex-end;
            margin-bottom: cssvar(spacing-1);
        }
    }
}
